import { createAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getBeneficiariesInit,
  getBeneficiariesSuccess,
  getBeneficiariesFailure,
  getBeneficiaryInit,
  getBeneficiaryFailure,
  getBeneficiarySuccess
} from './beneficiarySlice';

const getBeneficiary = createAction('beneficiaries/get');
const getBeneficiaries = createAction('beneficiaries/list');

/**
 * get a single beneficiary
 */
function* getBeneficiarySaga({ payload: beneficiaryId }) {
  try {
    yield put(getBeneficiaryInit());
    let endpoint = buildApiRoute(apiEndpoints.getBeneficiary, beneficiaryId);
    let response = yield callWrapperSaga(() => http.get(endpoint, beneficiaryId));
    yield put(getBeneficiarySuccess(response.data));
  } catch (err) {
    yield put(getBeneficiaryFailure(err));
  }
}

/**
 * get a beneficiaries list
 *
 */
function* getBeneficiariesSaga({ payload }) {
  yield put(getBeneficiariesInit());
  try {
    let endpoint = `${apiEndpoints.getBeneficiaries}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getBeneficiariesSuccess(response.data));
  } catch (err) {
    yield put(getBeneficiariesFailure(err));
  }
}

function* beneficiarySaga() {
  yield takeLatest(getBeneficiary, getBeneficiarySaga);
  yield takeLatest(getBeneficiaries, getBeneficiariesSaga);
}

export default beneficiarySaga;
export { getBeneficiary, getBeneficiaries };
