import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';
import {
  getCustomerKycsInit,
  getCustomerKycsSuccess,
  getCustomerKycsFailure,
  getCustomerKycInit,
  getCustomerKycFailure,
  getCustomerKycSuccess,
  changeKycStatusInit,
  changeKycStatusSuccess,
  changeKycStatusFailure
} from './customerKycSlice';

const getCustomerKyc = createAction('customerKycs/get');
const getCustomerKycs = createAction('customerKycs/list');
const changeKycStatus = createAction('customerKycs/changeStatus');

function* getCustomerKycSaga({ payload: customerKycId }) {
  try {
    yield put(getCustomerKycInit());
    let endpoint = buildApiRoute(apiEndpoints.getCustomerKyc, customerKycId);
    let response = yield callWrapperSaga(() => http.get(endpoint, customerKycId));
    yield put(getCustomerKycSuccess(response.data));
  } catch (err) {
    yield put(getCustomerKycFailure(err));
  }
}

function* getCustomerKycsSaga({ payload }) {
  yield put(getCustomerKycsInit());
  try {
    let endpoint = `${apiEndpoints.getCustomerKycs}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getCustomerKycsSuccess(response.data));
  } catch (err) {
    yield put(getCustomerKycsFailure(err));
  }
}

function* changeKycStatusSaga({ payload: { id, kyc_status } }) {
  yield put(changeKycStatusInit());
  try {
    let endpoint = buildApiRoute(apiEndpoints.updateCustomerKycStatus, id);
    yield callWrapperSaga(() => http.patch(endpoint + `?kyc_status=${kyc_status}`));
    yield put(changeKycStatusSuccess(kyc_status));
  } catch (err) {
    yield put(changeKycStatusFailure(err));
  }
}

function* customerKycSaga() {
  yield takeLatest(getCustomerKyc, getCustomerKycSaga);
  yield takeLatest(getCustomerKycs, getCustomerKycsSaga);
  yield takeLatest(changeKycStatus, changeKycStatusSaga);
}

export default customerKycSaga;
export { getCustomerKyc, getCustomerKycs, changeKycStatus };
