import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';

import Spacer from 'core/spacer/Spacer';
import Skeleton from 'core/skeleton/Skeleton';
import TableContainer from './TableContainer';
import FormSectionTitle from 'core/forms/FormSectionTitle';
import isEmpty from 'helpers/isEmpty';

export default function GroupedTableView({ title, data, item, isLoading }) {
  return (
    <>
      {title && (
        <Grid item xs={12}>
          <FormSectionTitle>{title}</FormSectionTitle>
        </Grid>
      )}
      <Grid item xs={12}>
        <TableContainer>
          <Table
            sx={{
              width: '100%',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell width="30%">Fields</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                'tr:hover': {
                  background: (theme) => theme.palette.grey[300],
                },
              }}
            >
              {data.map((viewDataItem, index) => (
                <TableRow key={index}>
                  {isLoading || isEmpty(item) ? (
                    <>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{viewDataItem.label}</TableCell>
                      <TableCell>
                        {Object.prototype.toString.call(viewDataItem?.get) ===
                        '[object Function]' ? (
                          <Box display="flex" justifyContent="start">
                            {viewDataItem.get(item)}
                          </Box>
                        ) : !isEmpty(item?.[viewDataItem.data]) ? (
                          item?.[viewDataItem.data]
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Spacer />
    </>
  );
}

GroupedTableView.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.string,
      get: PropTypes.func,
    }),
  ),
  item: PropTypes.object,
  isLoading: PropTypes.bool,
};

GroupedTableView.defaultProps = {
  item: {},
  isLoading: false,
};
