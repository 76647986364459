import { useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styled from '@mui/material/styles/styled';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';

import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import ArticleIcon from '@mui/icons-material/Article';
import PhishingIcon from '@mui/icons-material/Phishing';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import FaceTwoToneIcon from '@mui/icons-material/FaceTwoTone';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

import Topbar from 'layouts/Topbar';
import Sidebar from 'layouts/Sidebar';
import DrawerContent from './DrawerContent';
import LogoutModal from 'components/auth/LogoutModal';

import Auth from 'utils/Auth';
import Route from 'utils/Route';
import acl from 'app/config/acl';
import constants from 'app/config/constants';
import { buildRoute, currentRouteName } from 'routes';
import { setIsDrawerOpen } from 'app/features/app/appSlice';
import { getLoggedInUser } from 'app/features/auth/authSaga';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

function AppLayout() {
  const dispatch = useDispatch();

  const { isDrawerOpen: open } = useSelector((state) => state.app);

  const handleDrawerOpen = () => dispatch(setIsDrawerOpen(true));

  const handleDrawerClose = () => dispatch(setIsDrawerOpen(false));

  const location = useLocation();

  const sidebarItems = useMemo(() => {
    let activeRouteName = currentRouteName(location.pathname);
    return [
      {
        type: 'link',
        name: 'Home',
        icon: <DashboardIcon />,
        path: buildRoute('home'),
        isActive: activeRouteName === 'home' || activeRouteName === 'dashboard',
      },
      ...(Auth.isAdmin()
        ? [
            {
              type: 'link',
              name: 'Business Profile',
              icon: <BusinessIcon />,
              path: buildRoute('business-profile'),
              isActive: activeRouteName === 'business-profile',
            },
          ]
        : []),
      ...(Auth.isUser()
        ? [
            {
              type: 'link',
              name: 'Funding Sources',
              icon: <BusinessIcon />,
              path: buildRoute('partner-funding-sources.index'),
              isActive: activeRouteName === 'partner-funding-sources.index',
            },
            {
              type: 'link',
              name: 'Countries',
              icon: <PublicIcon />,
              path: buildRoute('partner-countries.index'),
              isActive: activeRouteName === 'partner-countries.index',
            },
          ]
        : []),
      {
        type: 'dropdown',
        name: 'Partners',
        icon: <PersonIcon />,
        isActive: Route.is('partners*'),
        children: [
          {
            type: 'link',
            path: buildRoute('partners.index'),
            isActive: activeRouteName === 'partners.index',
            name: 'Partners List',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('partners.create'),
            isActive: activeRouteName === 'partners.create',
            name: 'Add New Partner',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('partner-funding-sources.index'),
            isActive: activeRouteName === 'partner-funding-sources.index',
            name: 'Funding Sources',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('partner-countries.index'),
            isActive: activeRouteName === 'partner-countries.index',
            name: 'Countries',
            roles: [acl.SUPERADMIN],
          },
        ],
      },
      {
        type: 'dropdown',
        name: 'Accounts',
        icon: <AssignmentIndIcon />,
        isActive: Route.is('accounts*'),
        children: [
          {
            type: 'link',
            path: buildRoute('accounts.index'),
            isActive: activeRouteName === 'accounts.index',
            name: 'Account List',
            roles: [acl.SUPERADMIN, acl.ADMIN],
          },
          {
            type: 'link',
            path: buildRoute('accounts.create'),
            isActive: activeRouteName === 'accounts.create',
            name: 'Add Account',
            roles: [acl.SUPERADMIN, acl.ADMIN],
          },
        ],
      },
      {
        type: 'link',
        name: 'Credentials',
        icon: <SettingsSuggestIcon />,
        path: buildRoute('partner-credentials.index'),
        isActive: Route.is('partner-credentials*'),
        roles: [acl.ADMIN],
      },
      {
        type: 'dropdown',
        name: 'Transactions',
        icon: <WorkOutlineIcon />,
        isActive: Route.is('transactions*'),
        children: [
          {
            type: 'link',
            path: buildRoute('pending-bank-transactions.index'),
            isActive: Route.is('transactions/pending-bank-transactions'),
            name: 'Pending Bank Transactions',
            roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
          },
          {
            type: 'link',
            path: buildRoute('transactions.index'),
            isActive: Route.is('transactions'),
            name: 'All Transactions',
            roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
          },
        ],
      },
      {
        type: 'dropdown',
        name: 'Customers',
        icon: <FaceTwoToneIcon />,
        isActive: Route.is('customers*') || Route.is('customer-kycs*'),
        roles: [],
        children: [
          {
            type: 'link',
            path: buildRoute('customers.index'),
            isActive: activeRouteName === 'customers.index',
            name: 'Customer List',
            roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
          },
          {
            type: 'link',
            path: buildRoute('customer-kycs.index'),
            isActive: activeRouteName === 'customer-kycs.index',
            name: 'KYCs',
            roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
          },
        ],
      },
      {
        type: 'link',
        name: 'Beneficiaries',
        icon: <PeopleAltRoundedIcon />,
        path: buildRoute('beneficiaries.index'),
        isActive: Route.is('beneficiaries*'),
        roles: [acl.ADMIN, acl.SUPERADMIN, acl.USER],
      },
      {
        type: 'dropdown',
        name: 'Webhooks',
        icon: <PhishingIcon />,
        isActive: Route.is('webhooks*'),
        children: [
          {
            type: 'link',
            path: buildRoute('webhooks.index'),
            isActive: activeRouteName === 'webhooks.index',
            name: 'Webhook List',
            roles: [acl.SUPERADMIN, acl.ADMIN],
          },
          {
            type: 'link',
            path: buildRoute('webhooks.create'),
            isActive: activeRouteName === 'webhooks.create',
            name: 'Add New Webhook',
            roles: [acl.SUPERADMIN, acl.ADMIN],
          },
          {
            type: 'link',
            path: buildRoute('webhook-headers.index'),
            isActive: activeRouteName === 'webhook-headers.index',
            name: 'Webhook Headers',
            roles: [acl.SUPERADMIN],
          },
        ],
      },
      {
        type: 'dropdown',
        name: 'Reports',
        icon: <ArticleIcon />,
        isActive: Route.is('finmo/*'),
        children: [
          {
            type: 'link',
            path: buildRoute('finmo-payins.index'),
            isActive: activeRouteName === 'finmo-payins.index',
            name: 'Payins',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('finmo-payouts.index'),
            isActive: activeRouteName === 'finmo-payouts.index',
            name: 'Payouts',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('aus-track-reports'),
            isActive: activeRouteName === 'aus-track-reports',
            name: 'AusTrack Reports',
            roles: [acl.SUPERADMIN],
          },
        ],
      },
      {
        type: 'dropdown',
        name: 'Settings',
        icon: <SettingsIcon />,
        isActive: Route.is('api-configs*'),
        children: [
          {
            type: 'link',
            path: buildRoute('funding-sources.index'),
            isActive: activeRouteName === 'funding-sources.index',
            name: 'Funding Sources',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('api-configs.index'),
            isActive: activeRouteName === 'api-configs.index',
            name: 'API Configs',
            roles: [acl.SUPERADMIN],
          },
        ],
      },
      // {
      //   type: 'divider'
      // },
      // {
      //   type: 'sub_header',
      //   name: 'setup_and_settings'
      // },
    ];
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getLoggedInUser());
    sessionStorage.clear(constants.INTENDED_PATH);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Topbar />
      <Sidebar
        open={open}
        handleDrawerClose={handleDrawerClose}
        appBar={<DrawerContent />}
        sidebarItems={sidebarItems}
      />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
      <LogoutModal />
    </Box>
  );
}

export default AppLayout;
