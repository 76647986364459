import { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Tabs from 'core/tabs/Tabs';
import CustomerKycsTab from './tabs/CustomerKycsTab';
import CustomerDetailsTab from './tabs/CustomerDetailsTab';
import CustomerTransactionsTab from './tabs/CustomerTransactionsTab';
import CustomerBeneficiariesTab from './tabs/CustomerBeneficiariesTab';

import Auth from 'utils/Auth';
import { getCustomer } from 'app/features/customers/customerSaga';

function Customer({ customerId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomer(customerId));
  }, [customerId]);

  return (
    <Tabs
      tabs={[
        {
          key: 'customer-details',
          tabName: 'General Details',
          tabContent: <CustomerDetailsTab />,
        },
        {
          key: 'customer-beneficiaries',
          tabName: 'Beneficiaries',
          tabContent: <CustomerBeneficiariesTab customerId={customerId} />,
        },
        ...(Auth.isSuperAdmin() || Auth.isAdmin()
          ? [
              {
                key: 'customer-kycs',
                tabName: 'KYCs',
                tabContent: <CustomerKycsTab customerId={customerId} />,
              },
              {
                key: 'customer-transactions',
                tabName: 'Transactions',
                tabContent: <CustomerTransactionsTab customerId={customerId} />,
              },
            ]
          : []),
      ]}
    />
  );
}

export default memo(Customer);

Customer.propTypes = {
  customerId: PropTypes.string.isRequired,
};
