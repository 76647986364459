import { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
// web.cjs is required for IE11 support
import { useSpring, animated } from '@react-spring/web';
import FilterNoneRoundedIcon from '@mui/icons-material/FilterNoneRounded';
import CropLandscapeRoundedIcon from '@mui/icons-material/CropLandscapeRounded';

import { localStorageSave, localStorageGet } from 'helpers/localStorage';

const Fade = forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const ModalHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.primary.main,
}));

const ModalBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'enableDefaultPadding',
})(({ theme, enableDefaultPadding }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  color: theme.palette.grey[600],
  ...(enableDefaultPadding && {
    padding: theme.spacing(2),
  }),
}));

const ModalContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen' && prop !== 'style',
})(({ theme, isFullScreen, style }) => ({
  ...style,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: style?.minWidth ?? '300px',
  width: isFullScreen ? '90vw' : 'auto',
  height: isFullScreen ? '100vh' : 'auto',
  maxHeight: '90vh',
  maxWidth: style?.maxWidth ?? '85vw',
  backgroundColor: theme.palette.background.paper,
  boxShadow: 12,
  overflowY: 'auto',
  borderRadius: '0.2rem',
}));

export default function SpringModal({
  isOpen,
  onClose,
  children,
  header,
  fullScreen,
  style,
  sx,
  modalId,
  enableDefaultPadding,
  enableMaximizeButton,
  subtitle,
}) {
  const [isFullScreen, setIsFullScreen] = useState(
    fullScreen ?? localStorageGet('isReactSpringModalFullScreen') == 'true',
  );

  useEffect(() => {
    localStorageSave('isReactSpringModalFullScreen', String(isFullScreen));
  }, [isFullScreen]);

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div id={modalId ?? ''}>
          <ModalContainer
            style={{
              ...style,
              ...sx,
            }}
            isFullScreen={isFullScreen}
          >
            <ModalHeader>
              <Box>
                {typeof header === 'string' ? (
                  <Typography sx={{ color: '#fff' }}>{header}</Typography>
                ) : (
                  header
                )}
                {subtitle && (
                  <Typography sx={{ color: '#fff' }} variant="caption">
                    {subtitle}
                  </Typography>
                )}
              </Box>
              <Box>
                {enableMaximizeButton && (
                  <IconButton
                    onClick={() => setIsFullScreen(!isFullScreen)}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: 'white',
                      },
                    }}
                  >
                    {isFullScreen ? <FilterNoneRoundedIcon /> : <CropLandscapeRoundedIcon />}
                  </IconButton>
                )}

                <IconButton
                  onClick={onClose}
                  disabled={Object.prototype.toString.call(onClose) !== '[object Function]'}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </ModalHeader>
            <ModalBody enableDefaultPadding={enableDefaultPadding}>{children}</ModalBody>
          </ModalContainer>
        </div>
      </Fade>
    </Modal>
  );
}

SpringModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  fullScreen: PropTypes.bool,
  style: PropTypes.object,
  modalId: PropTypes.string,
  enableDefaultPadding: PropTypes.bool,
  enableMaximizeButton: PropTypes.bool,
  subtitle: PropTypes.string,
};

SpringModal.defaultProps = {
  isOpen: false,
  onClose: undefined,
  children: null,
  header: '',
  fullScreen: false,
  style: {},
  modalId: undefined,
  enableDefaultPadding: false,
  enableMaximizeButton: true,
};
