import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getCustomersInit,
  getCustomersSuccess,
  getCustomersFailure,
  getCustomerInit,
  getCustomerFailure,
  getCustomerSuccess,
} from './customerSlice';

const getCustomer = createAction('customers/get');
const getCustomers = createAction('customers/list');

/**
 * get a single customer
 */
function* getCustomerSaga({ payload: customerId }) {
  try {
    yield put(getCustomerInit());
    let endpoint = buildApiRoute(apiEndpoints.getCustomer, customerId);
    let response = yield callWrapperSaga(() => http.get(endpoint, customerId));
    yield put(getCustomerSuccess(response.data));
  } catch (err) {
    yield put(getCustomerFailure(err));
  }
}

/**
 * get a customers list
 *
 */
function* getCustomersSaga({ payload: { ...params } }) {
  yield put(getCustomersInit());
  try {
    let endpoint = `${apiEndpoints.getCustomers}?${qs.stringify(params)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getCustomersSuccess(response.data));
  } catch (err) {
    yield put(getCustomersFailure(err));
  }
}

function* customerSaga() {
  yield takeLatest(getCustomer, getCustomerSaga);
  yield takeLatest(getCustomers, getCustomersSaga);
}

export default customerSaga;
export { getCustomer, getCustomers };
