import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import CustomerKyc from 'components/customer-kycs/CustomerKyc';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import { resetGetCustomerKycFailure } from 'app/features/customer-kycs/customerKycSlice';

function ViewCustomerKyc({ isNetworkError }) {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isError, errorCode, errorMessage } = useSelector((state) => state.customerKycs.show);

  useEffect(() => {
    if (isError) dispatch(resetGetCustomerKycFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(resetGetCustomerKycFailure())}
      />
    );

  return (
    <PageContent
      title="CustomerKyc Details"
      rightTopEndContent={
        <Button onClick={() => navigate(buildRoute('customer-kycs.index'))}>
          Customer KYC List
        </Button>
      }
    >
      <CustomerKyc customerKycId={params.customerKycId} />
    </PageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ViewCustomerKyc);
