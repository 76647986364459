import React from 'react';
import PropTypes from 'prop-types';

export const If = ({ condition, children }) => {
  const validChildren = ['Then', 'Else'];

  const childrenArray = React.Children.toArray(children);

  if (childrenArray.length > 2) {
    throw new Error('If component can only have two children');
  }

  /**
   * this need a be fixed because component name get modified during build
   *
   * works on local | dev environment
   *
   */
  childrenArray.forEach((child) => {
    if (!validChildren.includes(child.type.displayName)) {
      throw new Error('If component can only have Then and Else as children');
    }
  });

  const thenValue = childrenArray.filter((child) => child.type.name === 'Then');
  const elseValue = childrenArray.filter((child) => child.type.name === 'Else');

  return <>{condition ? thenValue : elseValue}</>;
};

If.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

If.defaultProps = {
  condition: false,
};

export const Then = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>;
};

Then.displayName = 'Then';

export const Else = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>;
};

Else.displayName = 'Else';
