import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import KycStatus from 'core/badges/KycStatus';
import SidebarBriefModal from 'core/modal/SidebarBriefModal';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import CustomerClickAwayDetails from 'components/customers/CustomerClickAwayDetails';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import { buildRoute } from 'routes';
import { getCustomerKyc } from 'app/features/customer-kycs/customerKycSaga';
import { hideCustomerKycBriefDetails } from 'app/features/customer-kycs/customerKycSlice';

function CustomerKycBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewData = useMemo(
    () => [
      {
        label: 'Customer Name',
        data: 'user_name',
      },
      {
        label: 'Customer ID',
        data: 'user_id',
        get: ({ user_id }) => <CustomerClickAwayDetails customerId={user_id} />
      },
      {
        label: 'KYC Status',
        data: 'kyc_status',
        get: ({ kyc_status }) => <KycStatus status={kyc_status} />
      },
      {
        label: 'KYC Method',
        data: 'kyc_method'
      },
      {
        label: 'Kyc Type',
        data: 'kyc_type'
      },
      ...(Auth.isSuperAdmin()
        ? [
          {
            label: 'Partner ',
            data: 'partner_id',
            get: ({ partner_id, partner_name }) => (
              <PartnerClickAwayDetails label={partner_name} partnerId={partner_id} />
            )
          }
        ]
        : [])
    ],
    []
  );

  const state = useSelector((state) => state.customerKycs.show);

  const { showBriefDetailModal, customerKycId, isLoading, data } = state;

  useEffect(() => {
    if (customerKycId) dispatch(getCustomerKyc(customerKycId));
  }, [customerKycId]);

  if (!showBriefDetailModal && !customerKycId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideCustomerKycBriefDetails())}
      onClickView={() => (
        dispatch(hideCustomerKycBriefDetails()),
        navigate(buildRoute('customer-kycs.show', customerKycId))
      )}
      title="Customer KYC Details"
      viewData={viewData}
      item={data}
      isLoading={isLoading}
    />
  );
}

export default CustomerKycBriefDetailsModal;
